.headerSearch {
  @media screen and (min-width: 1024px) {
    width: 350px;
  }
  padding-left: 0 !important;
  input[type="search"] {
    padding-left: 0 !important;
  }
}
.border4 {
  border-radius: 4px;
}
.borderTop4 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
[type="email"] {
  width: 100%;
}
.contentData .tbl-layout-auto {
  table-layout: auto;
}
.custom-pagination ul {
  padding: 0;
}
.fui-Input input {
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.overflow-y-auto {
  overflow-x: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
[role="toolbar"] {
  margin-bottom: 20px;
  border-radius: 4px;
}
.fui-Listbox.fui-Combobox__listbox {
  max-height: 170px;
  overflow-y: auto;
}
.fui-AriaLive__assertive,
.fui-AriaLive__polite {
  display: none;
}
.timesheet-details th .fui-TableHeaderCell__button {
  justify-content: center;
}
.timesheet-details th:first-child .fui-TableHeaderCell__button {
  justify-content: left;
}

.timesheet-details .fui-TableCell:first-child {
  @media screen and (min-width: 992px) {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
    margin-left: -1px;
  }
}

.fui-Table.tbl-layout-auto .fui-Spinner {
  margin-top: 10px;
  margin-bottom: 10px;
}

.counterInput::placeholder,
.counterInput {
  text-align: center;
}

input[type="time"] {
  padding: 0;
}

[role="menu"] .fui-Divider:last-child {
  display: none;
}

.d-none {
  display: none;
}

.rdtCounter {
  width: 25px;
}

.rdtPicker .rdtCounter .rdtBtn:first-child {
  height: 25px;
  width: 25px;
  margin-bottom: 5px;
  background-image: url("../public/images/up-arrow.svg");
  background-repeat: no-repeat;
  content-visibility: hidden;
  background-size: cover;
}

.rdtPicker .rdtCounter .rdtBtn:last-child {
  background-image: url("../public/images/down-arrow.svg ");
  height: 25px;
  margin-top: 5px;
  width: 25px;
  background-repeat: no-repeat;
  content-visibility: hidden;
  background-size: cover;
}
.rdtPicker .rdtCounter {
  height: 80px;
}
.rdtPicker .rdtCounter .rdtCount {
  height: 20px;
}
.fui-Textarea textarea {
  padding-left: 18px;
}
.sidebarMenuTop .headerRightMenuItem .fui-MenuItemRadio__checkmark,
.bottomSubMenu .fui-MenuItemRadio__checkmark {
  display: none;
}

.sidebarMenuTop .headerRightMenuItem {
  padding: 0;
}
.sidebarMenuTop [role="menu"] {
  row-gap: 5px;
}

.headerRightMenuItem .headerLinkResp {
  line-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
